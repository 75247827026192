import { carRental } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { setError } from '@/store/setError';
import { portalHost } from '../../../../const/host.const';

const { getVehicleTelemetry } = carRental.vehicle;

export const scopes = {
  telemetry: 'telemetry',
};

const vehicleTelemetry = createCustomStore(({ runAsyncFlow }) => withScopes(scopes,
  (
    {
      mutations: {
        setError,
      },
      actions: {
        getVehicleTelemetry({ rootState, commit }, { vehicleVin, query }) {
          return runAsyncFlow(commit, {
            request: getVehicleTelemetry,
            params: [vehicleVin, query, portalHost(rootState)],
            scope: scopes.telemetry,
          });
        },
      },
    }
  )));

export default vehicleTelemetry;
