import { carRental } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { setError } from '@/store/setError';
import { portalHost } from '../../../../const/host.const';

const { getProcessingReasonsForPortal } = carRental.shared;
const { getVehiclePositionReasons } = carRental.shared;

export const scopes = {
  reasons: 'reasons',
  positionRevealReasons: 'positionRevealReasons',
};

const vehicleTrips = createCustomStore(({ runAsyncFlow }) => withScopes(scopes,
  (
    {
      mutations: {
        updateVehicleTripData(state, {
          dateRange,
          vehicleLicensePlate,
          comment,
          reason,
          userId,
        }) {
          state.dateRange = dateRange;
          state.vehicleLicensePlate = vehicleLicensePlate;
          state.comment = comment;
          state.reason = reason;
          state.userId = userId;
        },
        setError,
      },
      actions: {
        getProcessingReasons({ rootState, commit }) {
          runAsyncFlow(commit, {
            request: getProcessingReasonsForPortal,
            params: [portalHost(rootState)],
            scope: scopes.reasons,
          });
        },
        getVehiclePositionReasons({ rootState, commit }) {
          runAsyncFlow(commit, {
            request: getVehiclePositionReasons,
            params: [portalHost(rootState)],
            scope: scopes.positionRevealReasons,
          });
        },
      },
    }
  )));

export default vehicleTrips;
