import { carRental } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { clearErrors, toastError } from '@/utils';

import VehicleInfo from '../Info/store/VehicleInfoModule';
import VehicleEuropcar from '../Europcar/store/VehicleEuropcarModule';
import VehicleTelemetry from '../Telemetry/store/VehicleTelemetryModule';
import VehicleTrips from '../Trips/store/VehicleTripsModule';
import { portalHost } from '../../../const/host.const';

const {
  getVehicleSummary,
  getVehiclesExport,
  getVehicleMovements,
  getVehicleMovementsPaginated,
  getVehicleMovementsFilters,
  getStationGeofenceHistory,
  getVehicleGpsLocation,
} = carRental.vehicle;
const { getStationsList } = carRental.station;

export const scopes = {
  summary: 'summary',
  readings: 'readings',
  list: 'list',
  fleet: 'fleet',
  movements: 'movements',
  movementsList: 'movementsList',
  movementsFilters: 'movementsFilters',
  stationGeofenceHistory: 'stationGeofenceHistory',
  stations: 'stations',
  gpsLocation: 'gpsLocation',
};

const vehicles = createCustomStore(({ runAsyncFlow }) => withScopes(scopes,
  (
    {
      mutations: {
        setError(state, payload) {
          toastError.bind(this)(state, payload, [
            scopes.fleet,
            scopes.readings,
            scopes.movements,
            scopes.movementsList,
            scopes.movementsFilters,
            scopes.stationGeofenceHistory,
          ]);
        },
        resetSummaryData(state) {
          state.summary.data = [];
          state.readings.data = {};
          state.list.data = [];
        },
        resetGpsLocation(state) {
          state.gpsLocation.data = {};
        },
        clearErrors,
      },
      actions: {
        async getVehicleSummary({ rootState, commit }, { vehicleVin }) {
          return runAsyncFlow(commit, {
            request: getVehicleSummary,
            params: [vehicleVin, portalHost(rootState)],
            scope: scopes.summary,
          });
        },
        async getVehiclesExport({ rootState, commit }, { filters }) {
          return runAsyncFlow(commit, {
            request: getVehiclesExport,
            params: [filters, portalHost(rootState)],
            scope: scopes.list,
          });
        },
        getVehicleGpsLocation({ rootState, commit }, { vehicleVin, query }) {
          runAsyncFlow(commit, {
            request: getVehicleGpsLocation,
            params: [vehicleVin, query, portalHost(rootState)],
            scope: scopes.gpsLocation,
          });
        },
        getVehicleMovements({ rootState, commit }, { vehicleVin, data }) {
          runAsyncFlow(commit, {
            request: getVehicleMovements,
            params: [vehicleVin, data, portalHost(rootState)],
            scope: scopes.movements,
          });
        },
        getVehicleMovementsPaginated({ rootState, commit }, { vehicleVin, query }) {
          runAsyncFlow(commit, {
            request: getVehicleMovementsPaginated,
            params: [vehicleVin, query, portalHost(rootState)],
            scope: scopes.movementsList,
          });
        },
        getStationGeofenceHistory({ rootState, commit }, { vehicleVin, data }) {
          runAsyncFlow(commit, {
            request: getStationGeofenceHistory,
            params: [vehicleVin, data, portalHost(rootState)],
            scope: scopes.stationGeofenceHistory,
          });
        },
        getVehicleMovementsFilters({ rootState, commit }, vehicleVin) {
          runAsyncFlow(commit, {
            request: getVehicleMovementsFilters,
            params: [vehicleVin, portalHost(rootState)],
            scope: scopes.movementsFilters,
          });
        },
        async getStations({ rootState, commit }, query = {}) {
          return runAsyncFlow(commit, {
            request: getStationsList,
            params: [query, portalHost(rootState)],
            scope: scopes.stations,
          });
        },
      },
      modules: {
        VehicleEuropcar,
        VehicleInfo,
        VehicleTelemetry,
        VehicleTrips,
      },
    }
  )));

export default vehicles;
