import { carRental } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { setError } from '@/store/setError';
import { portalHost } from '../../../../const/host.const';

const { getVehicleGWStatus } = carRental.vehicle;

export const scopes = {
  gwstatus: 'gwstatus',
};

const vehicleGWStatus = createCustomStore(({ runAsyncFlow }) => withScopes(scopes,
  (
    {
      mutations: {
        setError,
      },
      actions: {
        getVehicleGWStatus({ rootState, commit }, vehicleVin) {
          runAsyncFlow(commit, {
            request: getVehicleGWStatus,
            params: [vehicleVin, portalHost(rootState)],
            scope: scopes.gwstatus,
          });
        },
      },
    }
  )));

export default vehicleGWStatus;
